import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import {CloudDownloadFill } from 'react-bootstrap-icons';
// import BeatLoader from "react-spinners/BeatLoader";
import { saveAs } from 'file-saver'

const Orders = () => {
    const localStorageData =  JSON.parse(localStorage.getItem('kt-auth-react-v'))
    // console.log(localStorageData);
    const token =  localStorageData.api_token;

    const patient_id =  localStorageData.user

    const [offset, setOffset] = useState(1);
    const [fromDate, setFromDate] = useState(); //new Date().toISOString().slice(0, 10) '2020-01-01'
    const [toDate, setToDate] = useState();
    const [sortBy, setSortBy] = useState(-1);
    const [searchQuery, setSearchQuery] = useState('');
    const [contentLoading, setContentLoading] = useState(false);
    const [allOrders, setAllOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [ordersParPage, setOrdersParPage] = useState(30)
    const [orderDetails, setOrderDetails] = useState([]);
    const [lgshow, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('View Order Details');
    const [viewMode, setViewMode] = useState(false);

    
    useEffect(() => {
        getOrders();
    }, [])


    const getOrderData = (data, offset) => {
        return (
            data.map(order =>{
                const order_data = order.order_details[0];
                const patient_details = order.patient;
                var download_btn = '';
                if(!order_data?.test_result || order_data?.test_result === null || order_data?.test_result === '' ){
                    download_btn = 'disabled';
                }
                
                return (
                <tr key={order_data._id}>
                    <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                        </div>
                    </td>
                    <td>
                        {order_data?.order_id}
                    </td>
                    <td>
                        {formatDate(order_data?.created_at)}
                    </td>
                    <td>
                        {
                            order_data.status === 1 ?
                            <><span className={`badge badge-light-primary`} style={{fontSize:"13px"}}>Pending</span></>: order_data.status === 2 ?
                            <><span className={`badge badge-light-info`}>Processing</span></>:  order_data.status === 3 ?
                            <><span className={`badge badge-light-success`}>Completed</span></>:order_data.status === 4 ?
                            <><span className={`badge badge-light-danger `}>Cancelled</span></>: ''
                        }
                    </td>
                    <td >
                        <strong>$ {order?.total_price}</strong>
                    </td>
                    <td>
                        <div className='d-flex flex-shrink-0'>
                            {/* e=>downloadReport(e, order_data?.test_result) */}
                            <button onClick={e=>downloadReport(order_data?._id)} disabled={download_btn}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='Download Report'
                            >
                            <CloudDownloadFill />
                            </button>
                            
                        </div>
                    </td>
                    <td className=''>
                        <div className='d-flex flex-shrink-0'>
                            <button onClick={() => viewDetails(order_data.order_id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='View Details'
                            >
                            <KTSVG
                                path='/media/icons/duotune/general/gen028.svg'
                                className='svg-icon-3'
                            />
                            </button>
                            
                        </div>
                    </td>
                </tr>
                )
            })
        )
    }
    
    const getOrders = async () => {
        setContentLoading(true);
        
        if (searchQuery === '' && fromDate === undefined && toDate === undefined && sortBy === -1) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/patient-all/${patient_id}`, { headers: {"Authorization" : ` ${token}`} })
            
            const data = res.data;
            const order_data = data.orders;
            const slice = order_data.slice(offset - 1 , offset - 1 + parseInt(ordersParPage));
            const contactData = getOrderData(slice);

            setAllOrders(contactData);
            setPageCount(Math.ceil(data.total / ordersParPage));
            setTotalItems(data.total);
        }else{
            getOrdersBySearch();
           
        }
        setContentLoading(false);
    }

    const getOrdersBySearch = async () => {
        // setContentLoading(true);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * ordersParPage +1);
        // console.log(newOffset);
        setOffset(newOffset);
        setOrdersParPage(ordersParPage);
    };
    const viewDetails = (order_id) => {
        try{
            axios.get(`${process.env.REACT_APP_API_URL}/orders/order-details-patient/${order_id}`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data.orders;
                setOrderDetails(data);
                handleShow("", 'View Order Details', 'View Order Details', true);

            }
            )
        }catch(err){
            console.log(err);
        }
    }

    const handleShow = (e, title="View Order Details", button="View Order Details", viewMode=false) => {
        if(viewMode){
            setViewMode(true);
        }else{
            setViewMode(false);
        }
    
    // console.log(title, button);
        setModalTitle(title);
        setModalTitle(button);
        setShow(true);
    }
    const handleClose = () => {
        setOrderDetails([]);
        setShow(false);

    }

    async function downloadReport(order_id) {
        const { data } = await getDownloadablePdf(order_id)
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, "result.pdf")
    }

    async function getDownloadablePdf(order_id) {
        return axios.get(`${process.env.REACT_APP_API_URL}/orders/get-pdf-result/${order_id}`, {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            responseType: 'arraybuffer'
        })
    }
    const formatDate = (date) => {
        // console.log(date);
        // Oct 19, 2022 9:00 AM
        const d = new Date(date);
        const year = d.getFullYear();
        const month = d.getMonth()+1;
        // month name
        const monthName = d.toLocaleString('default', { month: 'short' });

        const day = d.getDate();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();
        // hourse to 12 and add am pm
        const hours12 = hours > 12 ? hours - 12 : hours;
        const ampm = hours >= 12 ? 'PM' : 'AM';

        const minutes0 = minutes < 10 ? `0${minutes}` : minutes;
        const hours0 = hours12 < 10 ? `0${hours12}` : hours12;
        const seconds0 = seconds < 10 ? `0${seconds}` : seconds;

        const formattedDate = `${monthName} ${day}, ${year} ${hours0}:${minutes0} ${ampm}`;
        return formattedDate;
    }   
    return (
        <>
            <PageTitle breadcrumbs={[]}>My Orders</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Orders <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems<10?'0'+totalItems:totalItems}</span></span>
                    
                    </h3>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Order Number</th>
                                    <th className='min-w-150px'>Order Date</th>
                                    <th className='min-w-150px'>Order Status</th>
                                    {/* <th className='min-w-150px'>Order Total</th> */}
                                    <th className='min-w-150px'>Payment Amount</th>
                                    <th className='min-w-150px'>Result</th>
                                    <th className='min-w-50px'>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allOrders}
                            </tbody>
                        </table>
                        <div className='text-center'>
                            {/* <span>{totalItems}</span> */}
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                        </div>
                    </div>
                </div>
            </div>

            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
            <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                    {/* <Modal.Header closeButton >
                    <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                    </Modal.Header> */}
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>{modalTitle}</h1>
                        </div>

                        <Form>
                            <div className='row mb-5'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicStatus">
                                        <Form.Label>Status</Form.Label>
                                        <div>
                                            {
                                                orderDetails[0]?.status === 1 ?
                                                <><span className={`badge badge-light-primary`} style={{fontSize:"13px"}}>Pending</span></>: orderDetails[0]?.status === 2 ?
                                                <><span className={`badge badge-light-info`}>Processing</span></>:  orderDetails[0]?.status === 3 ?
                                                <><span className={`badge badge-light-success`}>Completed</span></>:orderDetails[0]?.status === 4 ?
                                                <><span className={`badge badge-light-danger `}>Cancelled</span></>: ''
                                            }
                                        </div>
                                            
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group controlId="TestName">
                                            <Form.Label>Order Number</Form.Label>: 
                                            <Form.Control className='form-control-sm' type="text" value={orderDetails[0]?.order_id} disabled={viewMode}/>
                                        </Form.Group>
                                    </div>
                            </div>
                            <div className='row pb-4'>
                                
                                <div className='col-md-6'>
                                    <Form.Group controlId="TestName">
                                        <Form.Label>Order Date</Form.Label>:
                                        <Form.Control className='form-control-sm' type="text" value={formatDate(orderDetails[0]?.created_at)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicStatus">
                                        <Form.Label>Payment Id</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" value={orderDetails[0]?.payment_id} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>


                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 border mt-5'>
                                <thead>
                                    <tr className='fw-bolder text-muted bg-light'>
                                        <th></th>
                                        <th className='min-w-150px'>Test Name</th>
                                        <th className='min-w-150px'>Price</th>
                                        <th className='min-w-150px'>Quantity</th>
                                        <th className='min-w-150px'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetails.map((item, index) => {
                                        // console.log(item);
                                        return (
                                            <tr key={index}>
                                                <td></td>
                                                <td>{item?.test_name}</td>
                                                <td>$ {item?.price}</td>
                                                <td>{item?.quantity}</td>
                                                <td>$ {item?.price * item?.quantity}</td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                                
                                    
                        
                        </Form>
                        {orderDetails[0]?.test_result || orderDetails[0]?.test_result !== null || orderDetails[0]?.test_result !== '' ?
                        <div className='row pb-4'>
                            <div className='col-md-6'>
                                <Form.Group controlId="TestName">
                                    <Form.Label>Test Result</Form.Label>: &nbsp;
                                    <button onClick={e=>downloadReport(orderDetails[0]?._id)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='Download Report'
                                        >
                                        <CloudDownloadFill />
                                    </button>
                                </Form.Group>
                            </div>
                            
                        </div>: ''}
                    </Modal.Body>
                        {/* modal footer */}
                        {viewMode ? '': (
                            <div className='modal-footer d-flex justify-content-center pt-4'>
                                <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                    Close
                                </button>
                                <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" >
                                    {modalTitle}
                                </button>
                            </div>
                        )}
                    </Modal>
        </>
    )
}

export default Orders
